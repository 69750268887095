.sliderContainer {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.sliderSteps {
  display: flex;
  flex-direction: row;
  transition: transform 0.5s ease-in-out;
}

.sliderStep {
  flex: 0 0 100%;
  padding: 0 20px;
  text-align: center;
  width: 100%;
}