.accordion-item {
	border: 1px solid #ccc;
	margin: 10px 0;
	border-radius: 4px;
}

.accordion-title {
	background-color: #f7f7f7;
	padding: 10px;
	cursor: pointer;
	font-weight: bold;
	border-bottom: 1px solid #ccc;
}

.accordion-content {
	padding: 10px;
	background-color: #fff;
}

.accordion-content ul {
	list-style-type: none;
	padding: 0;
}

.accordion-content li {
	padding: 5px 0;
}