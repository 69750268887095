/* src/styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --primary: #4E2873;
}

body {
  color: var(--primary);
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}


.field-required {
  margin-left: 4px;
  color: red;
}

.hero-image img {
  width: 100%;
  height: 160px;
  object-fit: cover;
}

main.page {
  color: var(--primary);
  position: relative;
}

.text-ellipsis-2-rows {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-more {
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
}

.btn-more:hover {
  text-decoration: underline;
}

.nav-bar {
  width: 100%;
  height: 40px;
  background-color: #4E2873;
  color: white;
  position: sticky;
  top: 50px;
  z-index: 99;

  p {
    font-size: 1rem;
  }

  span.nav-link {
    cursor: pointer;
    font-size: 1rem;

    &:hover {
      text-decoration: underline;
    }
  }
}

.announcements {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
