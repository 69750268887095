.btn-purple {
  background-color: #4E2873;
  border-color: #4E2873;

  &:hover {
    background-color: #5a3282;
    border-color: #5a3282;
  }

  &:active {
    background-color: #401e63;
    border-color: #401e63;
  }
}